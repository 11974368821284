import React from 'react';
import './App.css';
import Card from './components/Card';

function App() {
  return (<Card></Card>);

}

export default App;

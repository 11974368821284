import React, { useState } from "react";
import './Card.css';

function Card() {
    return (
        <div>
            <header className="mainHead" >
                Virtue
            </header>
        </div>
    );
}

export default Card;
